@import "../../../../node_modules/react-confirm-alert/src/react-confirm-alert.css";

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  background-color: transparent !important;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
}
