.health-bar-glass {
  display: flex;
  align-items: center;
  position: relative;
  width: 120px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 10px 0;
  border: 1px solid #000000;
}

@-webkit-keyframes anim0to100 {
  0% {
    width: 0%;
  }
  25% {
    width: 25%;
  }
  50% {
    width: 50%;
  }
  75% {
    width: 75%;
  }
  100% {
    width: 100%;
  }
}
@keyframes anim0to100 {
  0% {
    width: 0%;
  }
  25% {
    width: 25%;
  }
  50% {
    width: 50%;
  }
  75% {
    width: 75%;
  }
  100% {
    width: 100%;
  }
}
